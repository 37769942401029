<template>
  <div class="px-2">
    <div v-if="company.sbqc_record">
      <div class="text-gray-600 py-3 flex items-center">
        <span>检查结果 </span>
        <i
          v-if="company.sbqc_record.content.is_safe"
          class="el-icon-check w-10 text-center text-2xl text-green-400"
        />
        <i
          v-else
          class="el-icon-error w-10 text-center text-2xl text-red-400"
        />
        <span>{{ stamp2time(company.sbqc_record.create_time) }}</span>
      </div>
      <el-table
        :data="company.sbqc_record.content.sbqcList"
        border
        header-row-class-name="text-gray-600"
        cell-class-name="bg-gray-50"
      >
        <el-table-column type="index" width="30" />
        <el-table-column width="40">
          <template #default="scope">
            <i
              v-if="scope.row.is_safe"
              class="el-icon-check w-full text-center text-xl text-green-400"
            />
            <i
              v-else
              class="el-icon-error w-full text-center text-xl text-red-400"
            />
          </template>
        </el-table-column>
        <el-table-column label="征收项目" prop="zsxmMc" />
        <el-table-column label="征收品目" prop="zspmMc" />
        <el-table-column label="税（费）款所属期起" prop="skssqQ" width="140" />
        <el-table-column label="税（费）款所属期止" prop="skssqZ" width="140" />
        <el-table-column label="申报期限" prop="sbqx" width="90" />
        <el-table-column label="申报日期" prop="sbrq" width="90" />
      </el-table>

      <el-table
        :data="company.sbqc_record.content.cwbbList"
        border
        header-row-class-name="text-gray-600"
        cell-class-name="bg-gray-50"
      >
        <el-table-column type="index" width="30" />
        <el-table-column width="40">
          <template #default="scope">
            <i
              v-if="scope.row.is_safe"
              class="el-icon-check w-full text-center text-xl text-green-400"
            />
            <i
              v-else
              class="el-icon-error w-full text-center text-xl text-red-400"
            />
          </template>
        </el-table-column>
        <el-table-column label="财务、会计制度" prop="cwkjzd" />
        <el-table-column label="财务报表报送小类" prop="cwbsxlmc" />
        <el-table-column label="报送所属期起" prop="bsssqQ" width="140" />
        <el-table-column label="报送所属期止" prop="bsssqZ" width="140" />
        <el-table-column label="报送期限" prop="bsqx" width="90" />
        <el-table-column label="报送日期" prop="bsrq" width="90" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import useGetRecordByUid from "@/composition/useGetRecordByUid.js";
import { stamp2time } from "@/utils/timeParser.js";

export default defineComponent({
  name: "SbqcRecord",
  setup() {
    const company = ref({});
    useGetRecordByUid(company);

    return {
      company,
      stamp2time,
    };
  },
});
</script>
