import { onMounted } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"

export default (record) => {
  const store = useStore()
  const route = useRoute()
  const uid = route.params.uid
  const model = route.meta.model
  // const record = ref()

  onMounted(async() => {
    const res = await store.dispatch(`${model}/get`, uid)
    record.value = res.data
  })
}