export function stamp2time(stamp) {
  return new Date(stamp).toLocaleString();
}

export function stamp2date(stamp) {
  return new Date(stamp).toLocaleDateString();
}

export function daysBetween(start, end) {
  const s = start.getTime()
  const e = end.getTime()
  const mSeconds = e - s
  // (1000 milliseconds _ (60 minutes _ 60 seconds) * 24 hours)
  return Math.ceil(mSeconds / (1000 * 60 * 60 * 24))
}